import React from 'react'

const String = () => {
    return (
        <svg fill="#002e54" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 490.1 490.1">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            d="M444.1,0H45.9C20.9,0,0,20.9,0,45.9v398.3c0,25,20.9,45.9,45.9,45.9h398.3c25,0,45.9-20.9,45.9-45.9V45.9 C490,20.9,469.1,0,444.1,0z M449.3,444.1c0,3.1-2.1,5.2-5.2,5.2H45.9c-3.1,0-5.2-2.1-5.2-5.2V45.9c0-3.1,2.1-5.2,5.2-5.2h398.3 c3.1,0,5.2,2.1,5.2,5.2v398.2H449.3z"></path>
                        <path
                            d="M271.4,202.2c-4.6-10.7-15.2-17.7-26.9-17.7s-22.3,7-26.9,17.7L160,335.4c-11.3,28.1,25.4,38.9,36,15.6l11.6-26.7h75.1 l11.7,27c11,24.3,47,9.4,35.1-15.2L271.4,202.2z M222.1,288.8l22.9-52.1l21.9,52.1H222.1z"></path>
                        <path
                            d="M324.2,127.2H165.8c-11.5,0-20.9,9.4-20.9,20.9s9.4,20.9,20.9,20.9h158.5c11.5,0,20.9-9.4,20.9-20.9 S335.7,127.2,324.2,127.2z"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default String