import React from 'react'

const Plus = () => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M4 12H20M12 4V20" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"></path>
            </g>
        </svg>
    )
}

export default Plus