import React from 'react'
import DatabaseTable from '../../components/DatabaseTable'

const Database = () => {
    return (
        <div className='flex flex-col gap-4 py-5 items-center'>
            <DatabaseTable/>
        </div>
    )
}

export default Database