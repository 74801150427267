import React from "react";
import {Outlet} from "react-router-dom";

const Auth = () => {
    return (
        <React.Fragment>
            <Outlet/>
        </React.Fragment>
    )
}
export default Auth;